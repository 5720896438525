.support_container {
    max-width: 1240px;
    width: 100%;
    background: #fefefecf;
    padding: 1em 2em;
}

.support_text {
    font-size: 1em;
    color: black;
}

.support_button {
    border: none;
    border-radius: 5%;
    background-color: #fefefecf;
    transition: 500ms;
    z-index: 1;
}

.support_button:hover {
    background-color: #0529b429;
}

.support_link {
    text-decoration: none;
}