.accordion_custom {
    width: 90%;
    margin: 1em auto;
}

.accordion-item:last-of-type>.accordion-header .accordion-button {
    padding: .5em;
    border-radius: 0;
    width: 20%;
    margin: 0 auto;
}

.accordion_custom_item {
    border: none !important;
    background-color: #f4f4f4 !important;
}

.accordion_text {
    color: black;
    width: 100%;
    padding: 10px;
    font-size: .9em;
    font-family: Roboto, sans-serif;
    margin: 0 auto;
}

@media only screen and (max-width: 480px) {
    .accordion-item:last-of-type>.accordion-header .accordion-button {
        padding: .5em;
        border-radius: 0;
        width: 50%;
        margin: 0 auto;
    }
}