.header_container {
    padding: 0;
}

.header_menu {
    padding: 4em 2em 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.main_logo_container {
    margin: 0 auto;
    width: 80%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_logo {
    width: 100%;
    height: 100%;
    background-image: url("../../images/main_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    opacity: 50%;
    transition: 3s;
}

.main_logo:hover {
    opacity: 100%;
}


@media only screen and (max-width: 760px) {
    .main_logo_container {
        height: 40vh;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .main_logo_container {
        height: 25vh;
    }
  }