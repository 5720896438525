.main_info_container {
    max-width: 1240px;
    width: 100%;
    background: #151515cf;
    padding: 1em 2em;
}

.main_info_text {
    font-size: .9em;
    color: white;
    font-family: Roboto, sans-serif;
    margin: 0 auto;
}