.footer_container {
    max-width: 1240px;
    width: 100%;
    height: 1em;
    transition: 2s;
}

.footer_container:hover {
  height: 9em;
}

.footer_bg {
    min-width: 100%;
    min-height: 30%;
    padding: 1em 1em;
    background: white;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    justify-content: space-between;
}

.footer_logo_image {
    width: 30%;
    height: 30vh;
    background-image: url("../../images/footer_logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.fund_info {
    width: 30%;
    color: black;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.fund_p {
    margin: 0;
}

.footer_links{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media only screen and (max-width: 480px) {
    .footer_bg {
        flex-direction: column;
    }

    .fund_info {
        width: 100%;
        align-items: center;
    }

    .footer_logo_image {
        width: 50%;
        margin: 0 auto;
    }
    
    .footer_links{
        width: 100%;
        align-items: center;
    }
  }
