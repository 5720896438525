* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.container {
  max-width: 1240px;
  width: 100%;
  height: 100vh;
  background-color: white;
  position: relative;
}

.header_container {
  min-width: 100vw;
  max-height: 90vh;
  background-size: cover;
  display: flex;
  background-position: inherit;
  background-image: url("../src/images/bg_photo_cracked_wall.jpg");
}

@media only screen and (max-width: 760px) {
  .header_container {
    max-height: 50vh;
  }
}

@media only screen and (max-width: 480px) {
  .header_container {
    max-height: 30vh;
  }
}