.link_container {
    padding: 5px;
}

.link {
    color: #BF202F;
    text-decoration: none;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    opacity: 80%;
    transition: 1s;
}

.link:hover {
    color: black;
    opacity: 100%;
}
